.simple-header {
  width: 100%;
  padding: 0.875em 0;
  background-color: rgba(89, 82, 193, 1);
}

.simple-header--transparent {
  background: transparent;
}

.simple-header--floating {
  position: absolute;
  background-color: rgba(89, 82, 193, 0.8);
}

.simple-header__logo {
  width: 5.5em;
  display: flex;
  align-items: center;
}

.simple-header__logo img {
  width: 100%;
}

.simple-header__account {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.simple-header__account-label {
  margin-right: 0.5em;
  color: #fff;
}

.simple-header__account-avatar {
  width: 1.875em;
  height: 1.875em;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  font-weight: bold;
  color: #29b49c;
  background-color: #fff;
}
