.plan-creator__background {
  position: relative;
}

.plan-creator__background::before {
  content: "";
  display: block;
  position: absolute;
  top: 10%;
  bottom: 10%;
  left: 0;
  right: 0;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #5952c1;
  background-image: url("../../images/pattern.png");
  background-repeat: no-repeat;
}

.plan-creator__avatar {
  width: 100%;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.45);
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.45);
  background-color: grey;
}

.plan-creator__avatar > * {
  position: absolute;
}

.plan-creator__avatar img {
  width: 100%;
  height: 100%;
  display: block;
}

.plan-creator__avatar::after {
  content: "";
  padding-top: 100%;
  display: block;
}

.plan-creator__avatar-check {
  padding: 2px;
  top: 0.5em;
  right: 0.5em;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: #fff;
  z-index: 1;
  font-size: 2em;
  color: #28a745;
}

.plan-creator__avatar-footer {
  height: 6em;
  padding: 1.25em 1.5em 0 1.5em;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.plan-creator__avatar-name {
  color: #fff;
  font-size: 1.75em;
  font-weight: bold;
}

.plan-creator__message {
  margin-top: 1.25em;
  padding: 1.25em 1.5em;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #e5e4f5;
  font-weight: 600;
  font-style: italic;
}

.plan-creator__message::before {
  content: "";
  position: absolute;
  border: 12px solid transparent;
  border-bottom: 12px solid #e5e4f5;
  top: -22px;
  left: 20%;
}

.plan-creator__feature {
  margin-bottom: 1.75em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plan-creator__feature-icon {
  margin-bottom: 0.3125em;
  font-size: 2.25em;
  color: #5952c1;
}

.plan-creator__feature-title {
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.25em;
  text-align: center;
}
