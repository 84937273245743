.unit-field {
  min-width: 12em;
  display: flex;
  border-bottom: 1px solid #979797;
}

.unit-field__field {
  width: 7em;
  border: none;
  font-size: 1.25em;
}

.unit-field__field:focus {
  outline: none;
}

.unit-field__unit {
  padding-bottom: 0.5em;
  font-size: 1.875em;
}
