.switcher {
  display: inline-flex;
  border-radius: 5px;
  border: 1px solid #5952c1;
}

.switcher__option {
  padding: 0.5em 1em;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #5952c1;
  cursor: pointer;
  transition: all 0.3s;
}

.switcher__option:hover {
  background-color: rgba(89, 82, 193, 0.07);
}

.switcher__option.is-active {
  background-color: #5952c1;
  color: #fff;
}

.switcher .switcher__option:first-child {
  border-right: 1px solid #5952c1;
}
