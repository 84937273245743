.survey__row {
  margin-bottom: 1.25em;
  display: flex;
  justify-content: center;
}

.survey__question {
  text-align: center;
  font-size: 1.25em;
}
