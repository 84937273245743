.icon-text {
  display: flex;
  align-items: center;
}

.icon-text__image {
  width: 2.25em;
  min-width: 2.25em;
  margin-right: 1.25em;
}

.icon-text__image img {
  width: 100%;
}

.icon-text__text p {
  margin-bottom: 0;
}

.large-alert {
  padding: 1.875em;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgba(89, 82, 193, 0.17);
  border: 1px solid rgba(89, 82, 193, 0.3);
  color: #5952c1;
}

@media (min-width: 768px) {
  .large-alert {
    padding: 1.5em 4em;
  }
}

.price-table__section {
  margin-bottom: 1.25em;
}

.price-table__heading {
  margin-bottom: 0.625em;
  padding-bottom: 0.3125em;
  display: block;
  border-bottom: 1px solid #cfcfcf;
  font-weight: bold;
  text-transform: uppercase;
}

.price-table__row {
  padding: 0.3125em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-table__col {
  display: flex;
  align-items: center;
}

.price-table__row--underlined {
  margin-bottom: 0.3125em;
  padding-bottom: 0.625em;
  border-bottom: 1px solid #cfcfcf;
}

.price-table__text-striked {
  color: #828282;
  text-decoration: line-through;
}

.checklist {
  padding: 0;
  list-style: none;
}

.checklist__item {
  margin-bottom: 1.25em;
  padding-left: 1.75em;
  position: relative;
}

.checklist__item::before {
  content: "\f058";
  font-weight: bold;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 0;
  color: #5952c1;
}

.image-gallery {
  display: flex;
}

.image-gallery--window {
  flex-wrap: wrap;
}

.image-gallery__item {
  padding: 0.3125em;
}

.image-gallery__item-container {
  position: relative;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background-color: grey;
}

.image-gallery__item-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-gallery__item-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.image-gallery--window .image-gallery__item {
  width: 50%;
}
